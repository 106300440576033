var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { staticClass: "wm-100" }, [
    _c(
      "span",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.showEdit,
            expression: "!showEdit",
          },
        ],
      },
      [
        _c("b-form-input", {
          attrs: { trim: "", autocomplete: "off", state: !_vm.exists },
          on: { click: _vm.clickedValidatedValue, blur: _vm.validatedBlur },
          model: {
            value: _vm.validated_value,
            callback: function ($$v) {
              _vm.validated_value = $$v
            },
            expression: "validated_value",
          },
        }),
      ],
      1
    ),
    _c(
      "span",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showEdit,
            expression: "showEdit",
          },
        ],
      },
      [
        _c("b-form-input", {
          ref: "pnr",
          attrs: {
            trim: "",
            state: _vm.validated,
            autocomplete: "off",
            type: "tel",
            debounce: "500",
            placeholder: _vm.$t("FORMAT_PNR"),
          },
          on: {
            update: _vm.checkValue,
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
              )
                return null
              return _vm.onBlur.apply(null, arguments)
            },
            keypress: function ($event) {
              return _vm.isValidInput($event)
            },
            blur: _vm.onBlur,
          },
          model: {
            value: _vm.edit_value,
            callback: function ($$v) {
              _vm.edit_value = $$v
            },
            expression: "edit_value",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <section v-if="user.information">
    <div class="px-3">
      <div class="org-logo" v-if="user.information.organisation.image_uuid">
        <b-img :src="organisationImage" :alt="user.information.organisation.name"></b-img>
      </div>

      <div class="mt-4" v-if="!user.information.organisation.image_uuid">
        &nbsp;
      </div>

      <b-nav vertical class="mt-0">
        <b-nav-item :to="{ name: 'Dashboard' }" @click="clickedMenu"  id="menu_dashboard">
          <i class="fal fa-home"></i> {{ $t("DASHBOARD.TITLE") }}
        </b-nav-item>

        <b-nav-item :to="{ name: 'Messages' }" @click="clickedMenu" id="menu_messages"
          ><i class="fal fa-inbox"></i> {{ $t("MESSAGES.TITLE") }}
          <b-badge v-if="user.new_message != 0">{{
            user.new_message
          }}</b-badge></b-nav-item
        >

        <b-nav-item :to="{ name: 'Meetings' }" @click="clickedMenu"  v-if="meeting_enabled"
          ><i class="fal fa-video"></i> {{ $t("MEETINGS.TITLE") }}
        </b-nav-item>

        <b-nav-item @click="clickedMenu"  v-if="filestorage_enabled"
          :to="{ name: 'FileStorage' }"
          ><i class="fal fa-file"></i> {{ $t("FILESTORAGE.TITLE") }}
          <b-badge v-if="user.new_files != 0">{{
            user.new_files
          }}</b-badge></b-nav-item
        >

        <b-nav-item :to="{ name: 'Support' }" @click="clickedMenu"
          ><i class="fal fa-headset"></i> {{ $t("SUPPORT.TITLE") }}</b-nav-item
        >
      </b-nav>
      <Footer hide-locale></Footer>
    </div>
  </section>
</template>
<script>
import Footer from "@/components/Layout/Footer";
export default {
  components: { Footer },
  data() {
    return {
      newFiles: 0,
      showMobile: false,
      meeting_enabled: false,
      filestorage_enabled: false
    };
  },
  methods: {
    async init() {
      await this.checkMeetings();
      await this.checkFilestorage();
    },    
    async checkMeetings(){
      if(this.user.information)
      {
        try {
          let result = await this.$http.get(this.user.hostname + "/meeting/enabled");
          this.meeting_enabled = result.data;
        } catch {
          console.log("Could not fetch jitsi confidentially enabled");
        }
      }
    },
    async checkFilestorage() {
      if(this.user.information)
      {
        try {
          let result = await this.$http.get(this.user.hostname + "/filestorage/enabled");
          this.filestorage_enabled = result.data;
          if(result.data == true)
          {
            this.$store.dispatch("user/fetchFiles");
          }
        } catch {
          console.log("Could not checkFilestorage");
        }
      }
    },
    clickedMenu() {
      if(this.user.information)
      {
        if(this.filestorage_enabled)
        {
          this.$store.dispatch("user/fetchFiles");
        }
      }
      this.$emit("clickedMenu", true);
    },
    logoutAction() {
      this.$store.dispatch("user/NotAuthenticated");
      this.$router.push({ name: "Login" }).catch(() => {});
      this.$emit("clickedMenu", true);
    },
  },
  computed: {
    organisationImage() {
      return (
        this.user.hostname +
        "/organisation/image/" +
        this.user.information.organisation.id +
        "?uuid=" +
        this.user.information.organisation.image_uuid
      );
    },
    loggedIn() {
      return this.$store.state.user.information;
    },
  },
  watch: {
    'user.information': function (newVal, oldVal) {
      if(oldVal == null && newVal != null)
      {
        this.init();
      }
    }
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
</style>



<template>
  <section>
    <b-navbar class="sefos-header" toggleable="false">
      
      <b-navbar-nav>

        <b-nav-item
          v-b-toggle.mobile-menu
          class="hidden-not-mobile separator pr-0"
          v-if="user.information"
        >
          <i class="fa fa-bars nav-icon"></i><span class="sr-only">{{$t('TOGGLE_MENU')}}</span>
        </b-nav-item>

        <b-nav-item class="logo separator hidden-mobile"
          ><b-img alt="sefos logo" fluid src="/img/sefos_logo_white.png"></b-img
        ></b-nav-item>
        
        <b-nav-item class="logo hidden-not-mobile"
          ><b-img alt="sefos logo" fluid src="/img/sefos_logo_white.png"></b-img
        ></b-nav-item>

        <b-nav-item class="information hidden-mobile">
          <span class="sefos-text">SEFOS</span>&nbsp;<span class="admin-center"
            >for Web</span
          >
        </b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav class="ml-auto userinfo" v-if="user.information">


        <b-nav-item-dropdown
          v-bind:class="{ 'router-link-active': inAdministration }"
          class="ml-2 mr-2"
          toggle-class="header-administration"
          right
          id="header-administration"
          v-if="user.information.is_admin == 2"
          no-caret
          v-b-tooltip.hover
          :title="$t('ADMINISTRATION.TITLE')"
        >
          <template #button-content>
            <b-avatar><i class="fal fa-cog"></i> <span class="sr-only">{{$t('ADMINISTRATION.TITLE')}}</span></b-avatar>
          </template>

          <b-dropdown-item
            :title="$t('ORGANISATIONS')"
            v-if="user.information.is_admin == 2"
            :to="{ name: 'Administration.Organisations' }"
          >
            {{ $t("ORGANISATIONS") }}</b-dropdown-item
          >

          <b-dropdown-item
            v-if="showBilling"
            :to="{ name: 'Administration.Billing' }"
            >{{ $t("BILLING.TITLE") }}</b-dropdown-item
          >

          <b-dropdown-item
            :title="$t('NODES')"
            v-if="showNodes"
            :to="{ name: 'Administration.Nodes' }"
          >
            {{ $t("NODES") }}</b-dropdown-item
          >
          <b-dropdown-item
            :title="$t('AUTH_METHODS.TITLE')"
            v-if="user.information.is_admin == 2"
            :to="{ name: 'Administration.Authentications' }"
          >
            {{ $t("AUTH_METHODS.TITLE") }}</b-dropdown-item
          >
        </b-nav-item-dropdown>

        <b-nav-item
          v-if="user.information.is_admin >= 1"
          v-b-tooltip.hover
          link-classes="header-my-organisation"
          id="header-my-organisation"
          :title="$t('ORGANISATION.MY')"
          :to="{
            name: 'Organisation',
            params: { organisation_id: user.information.organisation.id },
          }"
        >
          <b-avatar><i class="fal fa-building"></i><span class="sr-only">{{$t('ORGANISATION.MY')}}</span></b-avatar>
        </b-nav-item>

        <b-nav-item-dropdown
          right
          no-caret
          z-index="1000"
          menu-class="header-big"
          class="ml-2 mr-2"
          v-b-tooltip.hover
          :title="$t('ACCOUNT')"
        >
          <template #button-content>
            <b-avatar><i class="fal fa-user-alt"></i><span class="sr-only">{{$t('ACCOUNT')}}</span></b-avatar>
          </template>
          <b-dropdown-text>
            <ProfileInfo />
          </b-dropdown-text> 
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item
            href="#"
            @click="show_user_sidebar = true"
            :title="$t('SETTINGS')"
            >{{ $t("SETTINGS") }}</b-dropdown-item
          >
          <b-dropdown-item
            href="#"
            @click="logoutAction"
            :title="$t('LOGOUT')"
            >{{ $t("LOGOUT") }}</b-dropdown-item
          >
        </b-nav-item-dropdown>

        <b-nav-text class="mr-2 hidden-mobile">
          <div class="navbarInfo">{{ $t('ADMINLEVEL.ADMINRIGHTS.' + user.information.is_admin) }}</div>
          <div class="navbarInfoSmall">{{ userLoa }}</div>
        </b-nav-text>        
       
      </b-navbar-nav>
    </b-navbar>

    <b-sidebar id="mobile-menu" title="" shadow v-model="showMobile">
      <div class="px-3 py-2">
        <Menu @clickedMenu="clickedMenu"></Menu>
      </div>
    </b-sidebar>

    <b-sidebar
      v-if="user.information"
      id="my-sidebar"
      right
      lazy
      z-index="1001"
      sidebar-class="big_sidebar"
      v-model="show_user_sidebar"
      backdrop
      no-slide
      shadow
    >
      <div class="px-3 py-2">
        <UserPage
          @updatedUser="updatedUser"
          :user_uuid="user.information.user_uuid"
        ></UserPage>
      </div>
    </b-sidebar>
  </section>
</template>
<script>
import UserPage from "@/components/User/Page";
import Menu from "@/components/Layout/Menu";
import ProfileInfo from "@/components/Layout/ProfileInfo";
export default {
  components: { UserPage, Menu, ProfileInfo },
  data() {
    return {
      show_user_sidebar: false,
      showMobile: false,
    };
  },
  methods: {
    clickedMenu() {
      this.showMobile = false;
    },
    logoutAction() {
      //let self = this;
      this.$store.dispatch("user/NotAuthenticated");
      this.$cookies.set("link_token", "", "1s", "", "", true, "None");
      this.$cookies.remove("redirect_url");
      this.$cookies.remove("link_token");
      this.$router.push({ name: "Login" }).catch(() => {});
      /*this.$http.get(this.user.hostname + "/logout").finally(function() {
        self.$store.dispatch("user/NotAuthenticated");      
         self.$router.push({ name: "Login" });  
      });*/
    },
    updatedUser() {
      this.$store.dispatch("user/fetchUser");
      //this.show_user_sidebar = false;
    },
  },
  computed: {
    showBilling(){
      return this.user.information.is_admin == 2 && this.adminSites.includes(window.location.hostname);
    },
    showNodes(){
      return this.user.information.is_admin == 2 && this.adminSites.includes(window.location.hostname);
    },
    adminSites(){
      return ["sefos.meaplus.com", "sefos.financialartsolutions.com", "test-meaplus.sefos.se", "robert-sefos.meaplus.com"]
    },
    primaryInfo(){
      let info = this.user.information.name;
      if(this.user.information.title)
      {
        info += ", " + this.user.information.title;
      }
      return info;
    },
    inAdministration() {
      return (
        this.$route.path.includes("administration") &&
        this.user.information.is_admin == 2
      );
    },
    organisationImage() {
      return "";
      /*
      if (this.user.information.organisation.image_uuid == null) {        
        return this.user.hostname + "/img/organisation.png";
      } else {
        return (
          this.user.hostname + "/organisation/image/" +
          this.user.information.organisation.id +
          "?" +
          this.user.information.organisation.image_uuid
        );
      }*/
    },
    userLoa() {
      if (this.user.information) {
        return this.$t("LOA.NAME") + " " + this.user.ticket.loa_level;
      } else {
        return "";
      }
    },
  },
};
</script>
<style></style>

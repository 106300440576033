var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "form",
        {
          staticClass: "mt-0 pt-0",
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "b-row",
            { staticClass: "mt-0 pt-0" },
            [
              _c(
                "b-col",
                [
                  _c("label", { staticClass: "col-form-label" }, [
                    _vm._v(_vm._s(_vm.$t("PASSWORD"))),
                  ]),
                  _c("b-form-input", {
                    ref: "password1",
                    attrs: { trim: "", type: "password", autocomplete: "off" },
                    on: { input: _vm.acceptData },
                    model: {
                      value: _vm.DataDTO.password1,
                      callback: function ($$v) {
                        _vm.$set(_vm.DataDTO, "password1", $$v)
                      },
                      expression: "DataDTO.password1",
                    },
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { state: _vm.errors.length == 0 } },
                    _vm._l(_vm.errors, function (item, index) {
                      return _c("div", { key: index }, [
                        _vm._v("\n                    * " + _vm._s(item)),
                      ])
                    }),
                    0
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c("label", { staticClass: "col-form-label" }, [
                    _vm._v(_vm._s(_vm.$t("RETYPE_PASSWORD"))),
                  ]),
                  _c("b-form-input", {
                    attrs: { trim: "", type: "password", autocomplete: "off" },
                    on: { input: _vm.acceptData, disabled: _vm.showDisabled },
                    model: {
                      value: _vm.DataDTO.password2,
                      callback: function ($$v) {
                        _vm.$set(_vm.DataDTO, "password2", $$v)
                      },
                      expression: "DataDTO.password2",
                    },
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { state: !_vm.password_not_matching } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("ERROR.PASSWORD_NOT_MATCHED")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("Phone", {
        staticClass: "mt-2",
        on: { valid: _vm.setvalidNumber },
        model: {
          value: _vm.DataDTO.identifier,
          callback: function ($$v) {
            _vm.$set(_vm.DataDTO, "identifier", $$v)
          },
          expression: "DataDTO.identifier",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <span>
    <span v-if="nameOnly">{{secondary_text}}</span>
    <div v-if="!nameOnly && (user_uuid || primary || secondary || optional)">
      <div v-if="image">
        <div :class="persona" v-if="showall">
          <div class="persona-image-area" v-if="image && !hideImage"><img alt="userlogo" :src="image" class="image" /></div>
          <div class="persona-details">
            <div class="primary-text" v-if="primary_text">{{ primary_text }}</div>
            <div class="secondary-text" v-if="secondary_text">          
              <span v-if="secondary_text" class="persona-optional-separator">{{secondary_text}}</span>
              <span v-if="optional_text" class="persona-optional"><span class="hidden-mobile">, </span>{{ optional_text }}</span>
            </div>
            <div class="secondary-text" v-if="!secondary_text">          
              <span class="persona-optional">{{ optional_text }}</span>
            </div>
          </div>
        </div>
        <div class="persona" v-if="!showall">
          <div class="persona-image-area"><img alt="userlogo" :src="image" class="image" /></div>
          <div class="persona-details">
            <div class="primary-text" v-if="primary_text">{{ primary_text }}</div>
            <div class="secondary-text" v-if="optional_text">
              {{ optional_text }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="!image">
        <span v-if="primary_text && !secondary_text">{{ primary_text }}</span>
        <span v-if="!primary_text && secondary_text">{{ secondary_text }}</span>
        <span v-if="primary_text && secondary_text">{{ primary_text }}, {{secondary_text}}</span>
      </div>
    </div>
  </span>
</template>
<script>
export default {
  props: {
    nameOnly: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    hideImage: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    user_uuid: {
      type: String,
      default: ""
    },
    image: {
      type: String,
      default: null,
    },
    primary: {
      type: String,
      default: null,
    },
    secondary: {
      type: String,
      default: null,
    },
    optional: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    department: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      primary_text: '',
      secondary_text: '',
      optional_text: ''
    };
  },
  methods: {
    getInformation(){
      if(this.user_uuid)
      {
        let self = this;
        this.$http.get(this.user.hostname + "/user/information/" + this.user_uuid)
                .then(function(result) {
                    //self.primary = result.data.name; 
                    self.secondary_text = result.data.name;
                });
      }
    }
  },
  computed: {
    persona(){
      if(this.hideImage){ return "persona no-image"; }
      return "persona";
    },
    showall() {
      return this.primary != this.secondary
    }
  },
  mounted(){
    this.primary_text = this.primary;
    this.secondary_text = this.secondary;
    this.optional_text = this.optional;
    this.getInformation();
  }
};
</script>
<style></style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-form-input", {
    attrs: { trim: "", autocomplete: "off", placeholder: _vm.placeholder },
    on: { input: _vm.acceptData },
    model: {
      value: _vm.input_value,
      callback: function ($$v) {
        _vm.input_value = $$v
      },
      expression: "input_value",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
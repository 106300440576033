<template>
  <section class="mt-3" v-if="userinfo">
    <div v-if="methods">

    <b-overlay :show="loading" rounded="sm">

      
      <h5 class="mt-3"  v-if="loadedMethods">{{ $t("AUTH_METHODS.SELECT_METHOD") }}</h5>
      <div class="mt-1" v-if="loadedMethods">
        <b-form-select
          @change="changedAuth"
          v-model="selectedIndex"
          :options="options"
        ></b-form-select>
      </div>

      <div class="mt-2" v-if="showAddMore" :key="editKey">
        <Pnr 
          required
          v-if="auth_type == 'se-eid'"
          v-on:keyup.enter="saveBtn"
          v-model="DataDTO.pnr"
          @valid="changeValid"
        ></Pnr>

        <Password 
          v-if="auth_type == 'password'"
          v-on:keyup.enter="saveBtn"
          @changed="changedData"
          @valid="changeValid"
        ></Password>

        <PasswordSms 
          v-if="auth_type == 'password-sms'"
          v-on:keyup.enter="saveBtn"
          @changed="changedData"
          edit-mode
          @valid="changeValid"
          :current-authentication-type="userinfo.authentication_type"
          :DataDTO="DataDTO"
        ></PasswordSms>

        <Identifier 
          v-if="showIdentifier"
          v-on:keyup.enter="saveBtn"
          @valid="changeValid"
          v-model="DataDTO.identifier"
          :placeholder="$t('UNIQUE_IDENTIFIER')"
        ></Identifier>
      </div>
      
      <hr class="p-0 m-0 mt-4 mb-2" />
      
      <b-button
        :disabled="!valid"
        variant="primary"
        class="btn-fill mt-2"
        @click="saveBtn"
        >{{ $t("SAVE") }}</b-button
      >

    </b-overlay>
    </div>
  </section>
</template>
<script>
import Pnr from "@/components/Input/Pnr.vue";
import Identifier from "@/components/Input/Identifier.vue";
import Password from "@/components/Input/Password.vue";
import PasswordSms from "@/components/Input/PasswordSms.vue";
export default {
  components: {  Pnr, Password, Identifier, PasswordSms },
  props: ["userinfo"],
  data() {
    return {
      selectedIndex: 0,
      selectedKey: 0,
      auth_method: this.userinfo.authentication_method,
      auth_type: this.userinfo.authentication_type,
      loading: false,
      valid: false,
      DataDTO: this.userinfo.authentication_data,
      methods: null,
      options: [],
      loadedMethods: false
    };
  },
  methods: {
    changedAuth() {
      this.selectedKey++;
      this.valid = false;
      let method = this.methods[this.selectedIndex];
      this.auth_type = method.auth_type;
      if(this.auth_type == this.userinfo.authentication_type){
        this.DataDTO = this.userinfo.authentication_data;
      }else{
        this.DataDTO = {
          identifier: "",
          pnr: "",
          password1: '',
          password2: ''
        };
      }
      if (method.auth_type == "custom") {
        this.auth_method = method.auth_method;
      }else{
        this.auth_method = "";
      }
      if (this.auth_type == "siths-card") {
        this.valid = true;
      }
      if (this.auth_type == "sefos-certificate") {
        this.valid = true;
      }
      if (this.auth_type == "custom") {
        this.valid = true;
      }
      this.data = {};
    },
    changeValid(valid) {
      this.valid = valid;
    },
    changedData(DataDTO) {
      this.DataDTO = DataDTO;
    },
    saveBtn: function() {
      if(!this.valid){
        return false;
      }
      let self = this;
      self.loading = true;
      this.$http
        .post(
          this.user.hostname + "/user/edit/authentication",
          {
            account_id: this.userinfo.id,
            data: this.DataDTO,
            auth_type: this.auth_type,
            auth_method: this.auth_method
          }
        )
        .then(function(result) {
          self.loading = false;
          self.$emit("updatedUser",result.data);
          self.$noty.info(self.$t("SAVED"));
        })
        .catch(function() {
          self.loading = false;
        });
    },
    async processMethods() {
      for (let ix = 0; ix < this.methods.length; ix++) {
        let method = this.methods[ix];
        let name = "";
        if(method.auth_type == "custom")
        {
          if(this.userinfo.authentication_type == "custom")
          {
            if(this.userinfo.authentication_method == method.auth_method)
            {
              this.selectedIndex = ix;
            }
          }
          name = method.custom_name;
        }else{
          if(this.auth_type == method.auth_type)
          {
            this.selectedIndex = ix;
          }
          name = this.$t("AUTH_METHODS." + method.auth_type)
        }
        this.options.push({
          value: ix,
          text: name
        });
        this.loadedMethods = true;
      }
      this.changedAuth();
    },
    async getMethods() {
      let self = this;
      self.loading = true;
      await this.$http
        .get(this.user.hostname + "/invite/methods/list")
        .then(function(result) {
          self.methods = result.data;
          self.loading = false;
          self.processMethods();
        })
        .catch(function() {
          self.loading = false;
        });
    }
  },
  computed: {
    editKey(){
      return "changed_" + this.selectedKey;
    },
    showAddMore(){
      if( (this.auth_type == "se-eid") || (this.auth_type == "password") || (this.auth_type == "password-sms") || this.showIdentifier)
      {
        return true;
      }else{
        return false;
      }

    },
    showIdentifier() {
      if(this.auth_type == "custom")
      {
        return this.methods[this.selectedIndex].needs_identifier == 1;
      }
      return this.auth_type == "freja-org";
    }
  },
  mounted() {
    this.getMethods();
  },
};
</script>
<style></style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "b-navbar",
        { staticClass: "sefos-header", attrs: { toggleable: "false" } },
        [
          _c(
            "b-navbar-nav",
            [
              _vm.user.information
                ? _c(
                    "b-nav-item",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle.mobile-menu",
                          modifiers: { "mobile-menu": true },
                        },
                      ],
                      staticClass: "hidden-not-mobile separator pr-0",
                    },
                    [
                      _c("i", { staticClass: "fa fa-bars nav-icon" }),
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v(_vm._s(_vm.$t("TOGGLE_MENU"))),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "b-nav-item",
                { staticClass: "logo separator hidden-mobile" },
                [
                  _c("b-img", {
                    attrs: {
                      alt: "sefos logo",
                      fluid: "",
                      src: "/img/sefos_logo_white.png",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-nav-item",
                { staticClass: "logo hidden-not-mobile" },
                [
                  _c("b-img", {
                    attrs: {
                      alt: "sefos logo",
                      fluid: "",
                      src: "/img/sefos_logo_white.png",
                    },
                  }),
                ],
                1
              ),
              _c("b-nav-item", { staticClass: "information hidden-mobile" }, [
                _c("span", { staticClass: "sefos-text" }, [_vm._v("SEFOS")]),
                _vm._v(" "),
                _c("span", { staticClass: "admin-center" }, [
                  _vm._v("for Web"),
                ]),
              ]),
            ],
            1
          ),
          _vm.user.information
            ? _c(
                "b-navbar-nav",
                { staticClass: "ml-auto userinfo" },
                [
                  _vm.user.information.is_admin == 2
                    ? _c(
                        "b-nav-item-dropdown",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true },
                            },
                          ],
                          staticClass: "ml-2 mr-2",
                          class: { "router-link-active": _vm.inAdministration },
                          attrs: {
                            "toggle-class": "header-administration",
                            right: "",
                            id: "header-administration",
                            "no-caret": "",
                            title: _vm.$t("ADMINISTRATION.TITLE"),
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "button-content",
                                fn: function () {
                                  return [
                                    _c("b-avatar", [
                                      _c("i", { staticClass: "fal fa-cog" }),
                                      _c("span", { staticClass: "sr-only" }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("ADMINISTRATION.TITLE"))
                                        ),
                                      ]),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            123227403
                          ),
                        },
                        [
                          _vm.user.information.is_admin == 2
                            ? _c(
                                "b-dropdown-item",
                                {
                                  attrs: {
                                    title: _vm.$t("ORGANISATIONS"),
                                    to: {
                                      name: "Administration.Organisations",
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.$t("ORGANISATIONS"))
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.showBilling
                            ? _c(
                                "b-dropdown-item",
                                {
                                  attrs: {
                                    to: { name: "Administration.Billing" },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("BILLING.TITLE")))]
                              )
                            : _vm._e(),
                          _vm.showNodes
                            ? _c(
                                "b-dropdown-item",
                                {
                                  attrs: {
                                    title: _vm.$t("NODES"),
                                    to: { name: "Administration.Nodes" },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n          " + _vm._s(_vm.$t("NODES"))
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.user.information.is_admin == 2
                            ? _c(
                                "b-dropdown-item",
                                {
                                  attrs: {
                                    title: _vm.$t("AUTH_METHODS.TITLE"),
                                    to: {
                                      name: "Administration.Authentications",
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.$t("AUTH_METHODS.TITLE"))
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.user.information.is_admin >= 1
                    ? _c(
                        "b-nav-item",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true },
                            },
                          ],
                          attrs: {
                            "link-classes": "header-my-organisation",
                            id: "header-my-organisation",
                            title: _vm.$t("ORGANISATION.MY"),
                            to: {
                              name: "Organisation",
                              params: {
                                organisation_id:
                                  _vm.user.information.organisation.id,
                              },
                            },
                          },
                        },
                        [
                          _c("b-avatar", [
                            _c("i", { staticClass: "fal fa-building" }),
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v(_vm._s(_vm.$t("ORGANISATION.MY"))),
                            ]),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-nav-item-dropdown",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "ml-2 mr-2",
                      attrs: {
                        right: "",
                        "no-caret": "",
                        "z-index": "1000",
                        "menu-class": "header-big",
                        title: _vm.$t("ACCOUNT"),
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "button-content",
                            fn: function () {
                              return [
                                _c("b-avatar", [
                                  _c("i", { staticClass: "fal fa-user-alt" }),
                                  _c("span", { staticClass: "sr-only" }, [
                                    _vm._v(_vm._s(_vm.$t("ACCOUNT"))),
                                  ]),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1028126180
                      ),
                    },
                    [
                      _c("b-dropdown-text", [_c("ProfileInfo")], 1),
                      _c("b-dropdown-divider"),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: { href: "#", title: _vm.$t("SETTINGS") },
                          on: {
                            click: function ($event) {
                              _vm.show_user_sidebar = true
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("SETTINGS")))]
                      ),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: { href: "#", title: _vm.$t("LOGOUT") },
                          on: { click: _vm.logoutAction },
                        },
                        [_vm._v(_vm._s(_vm.$t("LOGOUT")))]
                      ),
                    ],
                    1
                  ),
                  _c("b-nav-text", { staticClass: "mr-2 hidden-mobile" }, [
                    _c("div", { staticClass: "navbarInfo" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "ADMINLEVEL.ADMINRIGHTS." +
                              _vm.user.information.is_admin
                          )
                        )
                      ),
                    ]),
                    _c("div", { staticClass: "navbarInfoSmall" }, [
                      _vm._v(_vm._s(_vm.userLoa)),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-sidebar",
        {
          attrs: { id: "mobile-menu", title: "", shadow: "" },
          model: {
            value: _vm.showMobile,
            callback: function ($$v) {
              _vm.showMobile = $$v
            },
            expression: "showMobile",
          },
        },
        [
          _c(
            "div",
            { staticClass: "px-3 py-2" },
            [_c("Menu", { on: { clickedMenu: _vm.clickedMenu } })],
            1
          ),
        ]
      ),
      _vm.user.information
        ? _c(
            "b-sidebar",
            {
              attrs: {
                id: "my-sidebar",
                right: "",
                lazy: "",
                "z-index": "1001",
                "sidebar-class": "big_sidebar",
                backdrop: "",
                "no-slide": "",
                shadow: "",
              },
              model: {
                value: _vm.show_user_sidebar,
                callback: function ($$v) {
                  _vm.show_user_sidebar = $$v
                },
                expression: "show_user_sidebar",
              },
            },
            [
              _c(
                "div",
                { staticClass: "px-3 py-2" },
                [
                  _c("UserPage", {
                    attrs: { user_uuid: _vm.user.information.user_uuid },
                    on: { updatedUser: _vm.updatedUser },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
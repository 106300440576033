var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user.information
    ? _c("section", [
        _c(
          "div",
          { staticClass: "px-3" },
          [
            _vm.user.information.organisation.image_uuid
              ? _c(
                  "div",
                  { staticClass: "org-logo" },
                  [
                    _c("b-img", {
                      attrs: {
                        src: _vm.organisationImage,
                        alt: _vm.user.information.organisation.name,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.user.information.organisation.image_uuid
              ? _c("div", { staticClass: "mt-4" }, [_vm._v("\n       \n    ")])
              : _vm._e(),
            _c(
              "b-nav",
              { staticClass: "mt-0", attrs: { vertical: "" } },
              [
                _c(
                  "b-nav-item",
                  {
                    attrs: { to: { name: "Dashboard" }, id: "menu_dashboard" },
                    on: { click: _vm.clickedMenu },
                  },
                  [
                    _c("i", { staticClass: "fal fa-home" }),
                    _vm._v(
                      " " + _vm._s(_vm.$t("DASHBOARD.TITLE")) + "\n      "
                    ),
                  ]
                ),
                _c(
                  "b-nav-item",
                  {
                    attrs: { to: { name: "Messages" }, id: "menu_messages" },
                    on: { click: _vm.clickedMenu },
                  },
                  [
                    _c("i", { staticClass: "fal fa-inbox" }),
                    _vm._v(
                      " " + _vm._s(_vm.$t("MESSAGES.TITLE")) + "\n        "
                    ),
                    _vm.user.new_message != 0
                      ? _c("b-badge", [_vm._v(_vm._s(_vm.user.new_message))])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.meeting_enabled
                  ? _c(
                      "b-nav-item",
                      {
                        attrs: { to: { name: "Meetings" } },
                        on: { click: _vm.clickedMenu },
                      },
                      [
                        _c("i", { staticClass: "fal fa-video" }),
                        _vm._v(
                          " " + _vm._s(_vm.$t("MEETINGS.TITLE")) + "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.filestorage_enabled
                  ? _c(
                      "b-nav-item",
                      {
                        attrs: { to: { name: "FileStorage" } },
                        on: { click: _vm.clickedMenu },
                      },
                      [
                        _c("i", { staticClass: "fal fa-file" }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("FILESTORAGE.TITLE")) +
                            "\n        "
                        ),
                        _vm.user.new_files != 0
                          ? _c("b-badge", [_vm._v(_vm._s(_vm.user.new_files))])
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "b-nav-item",
                  {
                    attrs: { to: { name: "Support" } },
                    on: { click: _vm.clickedMenu },
                  },
                  [
                    _c("i", { staticClass: "fal fa-headset" }),
                    _vm._v(" " + _vm._s(_vm.$t("SUPPORT.TITLE"))),
                  ]
                ),
              ],
              1
            ),
            _c("Footer", { attrs: { "hide-locale": "" } }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { key: "footer_" + _vm.currentVersion, staticClass: "leftfooter" },
    [
      _c(
        "div",
        { staticClass: "text-center mt-2" },
        [
          1 == 0 ? _c("Locale") : _vm._e(),
          1 == 0
            ? _c("div", [
                _vm._v("      \n                Powered by "),
                _c("strong", [_vm._v("Nubibus Network")]),
                _c("div", { staticClass: "mt-1 copyright" }, [
                  _vm._v(
                    "Nubibus Software AB © " +
                      _vm._s(_vm.current_year) +
                      " " +
                      _vm._s(_vm.showVersion) +
                      _vm._s(_vm.serverVersion)
                  ),
                ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "copyright" }, [
            _vm._v(_vm._s(_vm.showVersion) + _vm._s(_vm.serverVersion)),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
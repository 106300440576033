var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "mt-3" },
    [
      _c(
        "b-overlay",
        { attrs: { show: _vm.loading, rounded: "sm" } },
        [
          _vm.user.information.is_admin > 0
            ? _c("h5", { staticClass: "mt-3" }, [
                _vm._v(_vm._s(_vm.$t("NAME"))),
              ])
            : _vm._e(),
          _vm.user.information.is_admin > 0
            ? _c(
                "div",
                { staticClass: "mt-1" },
                [
                  _c("b-form-input", {
                    attrs: {
                      trim: "",
                      required: "",
                      type: "text",
                      name: "name",
                    },
                    model: {
                      value: _vm.userinfo.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.userinfo, "name", $$v)
                      },
                      expression: "userinfo.name",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.user.information.is_admin > 0
            ? _c("h5", { staticClass: "mt-3" }, [
                _vm._v(_vm._s(_vm.$t("TITLE"))),
              ])
            : _vm._e(),
          _vm.user.information.is_admin > 0
            ? _c(
                "div",
                { staticClass: "mt-1" },
                [
                  _c("b-form-input", {
                    attrs: {
                      trim: "",
                      required: "",
                      type: "text",
                      name: "title",
                    },
                    model: {
                      value: _vm.userinfo.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.userinfo, "title", $$v)
                      },
                      expression: "userinfo.title",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.user.information.is_admin > 0
            ? _c("h5", { staticClass: "mt-3" }, [
                _vm._v(_vm._s(_vm.$t("DEPARTMENT"))),
              ])
            : _vm._e(),
          _vm.user.information.is_admin > 0
            ? _c(
                "div",
                { staticClass: "mt-1" },
                [
                  _c("b-form-input", {
                    attrs: {
                      trim: "",
                      required: "",
                      type: "text",
                      name: "name",
                    },
                    model: {
                      value: _vm.userinfo.department,
                      callback: function ($$v) {
                        _vm.$set(_vm.userinfo, "department", $$v)
                      },
                      expression: "userinfo.department",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.user.information.is_admin > 0
            ? _c("h5", { staticClass: "mt-3" }, [
                _vm._v(_vm._s(_vm.$t("ADMINLEVEL.TITLE"))),
              ])
            : _vm._e(),
          _vm.user.information.is_admin > 0
            ? _c(
                "div",
                { staticClass: "mt-1" },
                [
                  _c("b-form-select", {
                    attrs: { options: _vm.adminOptions },
                    model: {
                      value: _vm.userinfo.is_admin,
                      callback: function ($$v) {
                        _vm.$set(_vm.userinfo, "is_admin", $$v)
                      },
                      expression: "userinfo.is_admin",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.user.information.is_admin > 0
            ? _c("h5", { staticClass: "mt-3" }, [
                _vm._v(_vm._s(_vm.$t("LANGUAGE.TITLE"))),
              ])
            : _vm._e(),
          _vm.user.information.is_admin > 0
            ? _c(
                "div",
                { staticClass: "mt-1" },
                [
                  _c(
                    "b-form-select",
                    {
                      staticClass: "mb-3",
                      attrs: { name: "edit_user_select_language" },
                      model: {
                        value: _vm.userinfo.locale,
                        callback: function ($$v) {
                          _vm.$set(_vm.userinfo, "locale", $$v)
                        },
                        expression: "userinfo.locale",
                      },
                    },
                    _vm._l(_vm.sefos_locales, function (value, key) {
                      return _c(
                        "b-form-select-option",
                        { key: key, attrs: { value: key } },
                        [_vm._v(_vm._s(value))]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("hr", { staticClass: "p-0 m-0 mt-2 mb-2" }),
          _c(
            "b-button",
            {
              staticClass: "btn-fill",
              attrs: { variant: "primary" },
              on: { click: _vm.saveSubmit },
            },
            [_vm._v(_vm._s(_vm.$t("SAVE")))]
          ),
          _vm.userinfo.id != _vm.user.id
            ? _c(
                "b-button",
                {
                  staticClass: "btn-fill float-right",
                  attrs: { variant: "danger" },
                  on: { click: _vm.removeSubmit },
                },
                [_vm._v(_vm._s(_vm.$t("REMOVE")))]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
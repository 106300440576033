<template>
  <section>
    <b-form-input trim
      type="text"
      @update="checkValid"
      autocomplete="off"
      ref="phone"
      :state="valid"
      @keypress="isValidInput($event)"
      :placeholder="$t('PLACEHOLDER_SMS')"
      v-model="input_value"
    ></b-form-input>
  </section>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      Default: "",
    },
    required: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    exists: {
      type: Boolean,
      Default: false,
    }
  },
  data() {
    return {
      input_value: this.value
    };
  },
  methods: {
    isValidInput: function (evt) {
      let keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "+"];
      let keyPressed = evt.key;
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault();
      }
    },
    checkValid: function () {
      this.$emit("input", this.input_value.replace(/\s/g, ""));
      this.$emit("valid", this.valid);
    },
  },
  computed: {
    valid()
    {
      if(this.required)
      {
        return this.validatePhone(this.input_value) && !this.exists;
      }else{
        if(!this.required && this.input_value == "")
        {
          return null; 
        }else{
          return this.validatePhone(this.input_value) && !this.exists;
        }
      }
    }
  },
  mounted(){
    this.$emit("valid", this.valid);
  }
};
</script>
<style></style>
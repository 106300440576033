<template>
  <span class="wm-100">
    
      <span v-show="!showEdit">

          <b-form-input trim
            autocomplete="off"
            :state="!exists"
            v-model="validated_value"
            @click="clickedValidatedValue"
            @blur="validatedBlur"
          ></b-form-input>

      </span>
      <span v-show="showEdit">
        
        <b-form-input trim
          @update="checkValue"
          :state="validated"
          ref="pnr"
          autocomplete="off"
          type="tel"
          @keydown.tab="onBlur"
          @keypress="isValidInput($event)"
          debounce="500"
          :placeholder="$t('FORMAT_PNR')"
          v-model="edit_value"
          @blur="onBlur"
        ></b-form-input>
      </span>


  </span>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      Default: "",
    },
    required: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    exists: {
      type: Boolean,
      Default: false,
    }
  },
  data() {
    return {
      validated_value: "",
      edit_value: this.value,
      showEdit: true,
      input_value: "",
    };
  },
  methods: {
    editPnr(){
      this.edit_value = "";
      this.showEdit = true;
    },
    isValidInput: function (evt) {
      let keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "-"];
      let keyPressed = evt.key;
      if (keyPressed == "-") {
        if (this.value.length != 8 && this.value.length != 6) {
          evt.preventDefault();
        }
      }
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault();
      }
    },
    validatedBlur() {
      //console.log("validatedBlur");
      /*if (this.hasBlur) {
        this.$emit("blur");
      }*/
    },
    onBlur() {
      //console.log("onBlur");
      if (this.validatePNum(this.value)) {
        this.showEdit = false;
        this.validated_value = this.value.split("-")[0] + "-XXXX";
      }
      this.$emit("valid", this.validated);
      if (this.hasBlur) {
        this.$emit("blur");
      }
    },
    clickedValidatedValue() {
      //console.log("clickedValidatedValue");
      this.showEdit = true;
      let self = this;
      setTimeout(function () {
        self.$refs["pnr"].$el.focus();
      }, 70);
    },
    async AddDash(value) {
      ////console.log(value.length);
      if (value.length > 10 && value.indexOf("-") == -1) {
        value = value.substring(0, 8) + "-" + value.substring(8);
      }
      return value;
    },
    async AddFirstDigits(value) {
      if (value.length >= 10) {
        if (value.startsWith("0") || value.startsWith("1")) {
          let check = this.validatePNum("20" + value);
          if (check) {
            return "20" + value;
          }
        } else {
          let check = this.validatePNum("19" + value);
          if (check) {
            return "19" + value;
          }
        }
      }
      return value;
    },
    async checkValue(value) {
      value = value.replace(/\s/g, "");
      /*
      YYMMDDXXXX 10
      YYMMDD-XXXX 11
      YYYYMMDDXXXX 12
      YYYYMMDD-XXXX 13
      */
      if (value.length > 13) {
        value = value.substring(0, 12);
      }
      value = await this.AddFirstDigits(value);
      value = await this.AddDash(value);
      if (value.length == 13) {
        if (this.validatePNum(value)) {
          this.validated_value = value.split("-")[0] + "-XXXX";
          this.showEdit = false;
        }
      }
      this.edit_value = value;
      this.$emit("input", value);
      this.$emit("valid", this.validated);
    },
  },
  computed: {
    hasBlur() {
      return !!this._events["blur"];
    },
    getClass() {
      if (this.validated) {
        return ["form-control"];
      } else {
        return ["is-invalid", "form-control"];
      }
    },
    validated() {
      if (!this.required && this.value == "") {
        return null;
      }
      return this.validatedPnr && !this.exists;
    },
    validatedPnr(){
      return this.validatePNum(this.value); 
    }
  },
  mounted() {
    this.$refs["pnr"].$el.focus();
    this.$emit("valid", false);
  },
};
</script>
<style></style>

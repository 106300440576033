var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "b-dropdown",
        {
          staticClass: "m-2 no-border",
          attrs: {
            id: "dropdown-dropup",
            dropup: "",
            size: "sm",
            "toggle-class": "text-decoration-none",
          },
          scopedSlots: _vm._u([
            {
              key: "button-content",
              fn: function () {
                return [
                  _c("b-img", {
                    staticClass: "flag",
                    attrs: { src: _vm.currentFlag, alt: _vm.getSelectedValue },
                  }),
                  _vm._v(" " + _vm._s(_vm.getSelectedValue) + "\n    "),
                ]
              },
              proxy: true,
            },
          ]),
        },
        _vm._l(_vm.sefos_locales, function (value, key) {
          return _c(
            "b-dropdown-item",
            {
              key: key,
              on: {
                click: function ($event) {
                  return _vm.setLocale(key)
                },
              },
            },
            [
              _c("b-img", {
                staticClass: "flag",
                attrs: {
                  src: _vm.getFlag(key),
                  alt: _vm.$t("LANGUAGE." + key),
                },
              }),
              _vm._v("\n      " + _vm._s(_vm.$t("LANGUAGE." + key)) + "\n    "),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _vm.nameOnly ? _c("span", [_vm._v(_vm._s(_vm.secondary_text))]) : _vm._e(),
    !_vm.nameOnly &&
    (_vm.user_uuid || _vm.primary || _vm.secondary || _vm.optional)
      ? _c("div", [
          _vm.image
            ? _c("div", [
                _vm.showall
                  ? _c("div", { class: _vm.persona }, [
                      _vm.image && !_vm.hideImage
                        ? _c("div", { staticClass: "persona-image-area" }, [
                            _c("img", {
                              staticClass: "image",
                              attrs: { alt: "userlogo", src: _vm.image },
                            }),
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "persona-details" }, [
                        _vm.primary_text
                          ? _c("div", { staticClass: "primary-text" }, [
                              _vm._v(_vm._s(_vm.primary_text)),
                            ])
                          : _vm._e(),
                        _vm.secondary_text
                          ? _c("div", { staticClass: "secondary-text" }, [
                              _vm.secondary_text
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "persona-optional-separator",
                                    },
                                    [_vm._v(_vm._s(_vm.secondary_text))]
                                  )
                                : _vm._e(),
                              _vm.optional_text
                                ? _c(
                                    "span",
                                    { staticClass: "persona-optional" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "hidden-mobile" },
                                        [_vm._v(", ")]
                                      ),
                                      _vm._v(_vm._s(_vm.optional_text)),
                                    ]
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        !_vm.secondary_text
                          ? _c("div", { staticClass: "secondary-text" }, [
                              _c("span", { staticClass: "persona-optional" }, [
                                _vm._v(_vm._s(_vm.optional_text)),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                    ])
                  : _vm._e(),
                !_vm.showall
                  ? _c("div", { staticClass: "persona" }, [
                      _c("div", { staticClass: "persona-image-area" }, [
                        _c("img", {
                          staticClass: "image",
                          attrs: { alt: "userlogo", src: _vm.image },
                        }),
                      ]),
                      _c("div", { staticClass: "persona-details" }, [
                        _vm.primary_text
                          ? _c("div", { staticClass: "primary-text" }, [
                              _vm._v(_vm._s(_vm.primary_text)),
                            ])
                          : _vm._e(),
                        _vm.optional_text
                          ? _c("div", { staticClass: "secondary-text" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.optional_text) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          !_vm.image
            ? _c("div", [
                _vm.primary_text && !_vm.secondary_text
                  ? _c("span", [_vm._v(_vm._s(_vm.primary_text))])
                  : _vm._e(),
                !_vm.primary_text && _vm.secondary_text
                  ? _c("span", [_vm._v(_vm._s(_vm.secondary_text))])
                  : _vm._e(),
                _vm.primary_text && _vm.secondary_text
                  ? _c("span", [
                      _vm._v(
                        _vm._s(_vm.primary_text) +
                          ", " +
                          _vm._s(_vm.secondary_text)
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
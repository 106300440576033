<template>
  <section>
    <form @submit.prevent  class="mt-0 pt-0">

      <b-row class="mt-0 pt-0">
        <b-col>
          <label class="col-form-label">{{ $t("PASSWORD") }}</label>
          <b-form-input trim
            type="password"
            @input="acceptData"
            autocomplete="off"
            ref="password1"
            v-model="DataDTO.password1"
          ></b-form-input>
          <b-form-invalid-feedback :state="errors.length == 0">
            <div
                      v-for="(item,index) in errors"
                      :key="index">
                      * {{item}}</div>
          </b-form-invalid-feedback>

        </b-col>
        <b-col>
          <label class="col-form-label">{{ $t("RETYPE_PASSWORD") }}</label>
          <b-form-input trim
            type="password"
            @input="acceptData"
            @disabled="showDisabled"
            autocomplete="off"
            v-model="DataDTO.password2"
          ></b-form-input>

          <b-form-invalid-feedback :state="!password_not_matching">
            {{ $t("ERROR.PASSWORD_NOT_MATCHED") }}
          </b-form-invalid-feedback>
        </b-col>
      </b-row>
    </form>
    <Phone class="mt-2" v-model="DataDTO.identifier" @valid="setvalidNumber"></Phone>    
  </section>
</template>
<script>
import Phone from "../Input/Phone";
export default {
  components: {  Phone },
  props: {
    DataDTO: {
      default: null,
      type: Object,
    },
    currentAuthenticationType: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      validNumber: false,
      score: 0,
      errors: [],
			rules: [
				{ message: this.$t('ERROR.PASSWORD.ONE_LOWERCASE_REQUIRED'), regex:/[a-z]+/ },
				{ message: this.$t('ERROR.PASSWORD.ONE_UPPERCASE_REQUIRED'),  regex:/[A-Z]+/ },
				{ message: this.$t('ERROR.PASSWORD.CHARACTERS_REQUIRED'), regex:/.{8,}/ },
				{ message: this.$t('ERROR.PASSWORD.ONE_NUMBER_REQUIRED'), regex:/[0-9]+/ }
			]
    };
  },
  methods: {
    setvalidNumber(val){
      this.validNumber = val;
      this.acceptData();
    },
    setValid(val)
    {
      this.$emit('valid',val);
    },
    acceptData: function() {
      if(!this.validNumber)
      {
        this.setValid(false);
        return false;
      }
      if(this.currentAuthenticationType == "password-sms")
      { 
        if( this.DataDTO.identifier == "" ){
          this.setValid(false);
          return false;
        }
        if (this.DataDTO.password1 != "") {
          let errors = []
          for (let condition of this.rules) {
            if (!condition.regex.test(this.DataDTO.password1)) {
              errors.push(condition.message)
            }
          }
          if (errors.length === 0) {
            this.errors = [];
          } else {
            this.errors = errors;
            this.setValid(false);
            return false;
          }
          if (this.password1 != this.password2) {
            this.setValid(false);
            return false;
          }
        }
        this.setValid(true);
        this.$emit("changed", this.DataDTO);
      }else{
        if( this.DataDTO.password1 == ""){
          this.setValid(false);
          return false;
        }
        if( this.DataDTO.password2 == ""){
          this.setValid(false);
          return false;
        }
        if( this.DataDTO.identifier == "" ){
          this.setValid(false);
          return false;
        }
        let errors = []
        for (let condition of this.rules) {
          if (!condition.regex.test(this.DataDTO.password1)) {
            errors.push(condition.message)
          }
        }
        if (errors.length === 0) {
          this.errors = [];
        } else {
          this.errors = errors;
          this.setValid(false);
          return false;
        }
        if (this.password1 != this.password2) {
          this.setValid(false);
          return false;
        }
        this.setValid(true);
        this.$emit("changed", this.DataDTO);
      }
    }
  },
  watch: {
    identifier: function() {
      this.acceptData();
    }
  },
  computed: {
    password_not_matching() {
      return this.DataDTO.password1 != this.DataDTO.password2;
    },
    showDisabled(){
      return this.DataDTO.password1 == "";
    }
  }
};
</script>
<style></style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("b-form-input", {
        ref: "phone",
        attrs: {
          trim: "",
          type: "text",
          autocomplete: "off",
          state: _vm.valid,
          placeholder: _vm.$t("PLACEHOLDER_SMS"),
        },
        on: {
          update: _vm.checkValid,
          keypress: function ($event) {
            return _vm.isValidInput($event)
          },
        },
        model: {
          value: _vm.input_value,
          callback: function ($$v) {
            _vm.input_value = $$v
          },
          expression: "input_value",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div
    id="sidebar-wrapper"
    class="d-flex flex-column h-100"
        backdrop
      no-slide
      shadow

    v-if="user.information"
  >
    <Menu @clickedMenu="clickedMenu"></Menu>
  </div>
</template>
<script>
import Menu from "@/components/Layout/Menu";
export default {
  components: { Menu },
  data() {
    return {
    };
  },
  methods: {
    clickedMenu(){
      this.$emit('clickedMenu')
    }
  },
  computed: {},
  created: function() {},
};
</script>
<style></style>

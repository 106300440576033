<template>
  <section class="mt-3">
    <b-overlay :show="loading" rounded="sm">
      
      <h5 v-if="user.information.is_admin > 0">{{ $t("NAME") }}</h5>
      <div class="mt-1" v-if="user.information.is_admin > 0">
        <b-form-input trim
          required
          type="text"
          v-model="userinfo.name"
          name="name"
        ></b-form-input>
      </div>
      
      <h5 class="mt-3"  v-if="user.information.is_admin > 0">{{ $t("TITLE") }}</h5>
      <div class="mt-1"  v-if="user.information.is_admin > 0">
        <b-form-input trim
          required
          type="text"
          v-model="userinfo.title"
          name="title"
        ></b-form-input>
      </div>
      
      <h5 class="mt-3" v-if="user.information.is_admin > 0">{{ $t("DEPARTMENT") }}</h5>
      <div class="mt-1"  v-if="user.information.is_admin > 0">
        <b-form-input trim
          required
          type="text"
          v-model="userinfo.department"
          name="department"
        ></b-form-input>
      </div>

      <h5 class="mt-3" v-if="user.information.is_admin > 0">{{ $t("LANGUAGE.TITLE") }}</h5>
      <div class="mt-1"  v-if="user.information.is_admin > 0">      
        <b-form-select  name="edit_user_select_language" v-model="userinfo.locale">
          <b-form-select-option
            v-for="(value, key) in sefos_locales"
            :key="'edit_user_select_language_' + key"
            :value="key"
            >{{ value }}</b-form-select-option
          >
        </b-form-select>
      </div>

      
      <hr class="p-0 m-0 mt-4 mb-2" />

      <b-button variant="primary" class="mt-2 btn-fill" @click="saveSubmit">{{
        $t("SAVE")
      }}</b-button>
    </b-overlay>
  </section>
</template>
<script>
export default {
  props: ["userinfo"],
  data() {
    return {
      loading: false,
      adminOptions: [
        { value: 0, text: this.$t("ADMINLEVEL.0") },
        { value: 1, text: this.$t("ADMINLEVEL.1") },
        { value: 2, text: this.$t("ADMINLEVEL.2") },
      ],
    };
  },
  methods: {
    saveSubmit: function() {
      let self = this;
      this.loading = true;
      this.$http
        .post(this.user.hostname + "/user/edit", this.userinfo)
        .then(function(result) {
          self.$noty.info(self.$t("SAVED"));
          self.$emit("updatedUser", result.data);
          self.loading = false;
        })
        .catch(function() {
          self.loading = false;
        });
    }
  },
  computed: {
  },
  mounted: function() {},
};
</script>
<style></style>

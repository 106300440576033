<template>
  <section v-if="user_uuid">
    <div class="mt-2" v-if="information">
      <h3>
        <b-img
          class="profileImage"
          src="/img/default-avatar.png"
          :alt="information.name"
          rounded="circle"
        ></b-img>
        {{ information.name }}
      </h3>
      
      <b-tabs>
        
        <b-tab lazy>
          <template v-slot:title>{{
              $t("INFORMATION")
            }}
          </template>  
          <Information :userinfo="information"></Information>
        </b-tab>
        
        <b-tab lazy v-if="user.information.user_uuid == information.user_uuid">
          <template v-slot:title>
           {{
            $t("SETTINGS")
          }}
          </template>  
          <div class="mt-2">
            <b-tabs content-class="mt-2">
              <b-tab :title="$t('INFORMATION')" active>
                <Settings @updatedUser="updatedUser" :userinfo="information"></Settings>       
              </b-tab>
              <b-tab :title="$t('AUTH_METHODS.TITLE')"  v-if="user.information.is_admin > 0">
                <EditAuth @updatedUser="updatedUser" :userinfo="information" ></EditAuth>  
              </b-tab>
              <b-tab :title="$t('OUT_OF_OFFICE')">
                <EditOutOfOffice :userinfo="information"></EditOutOfOffice>
              </b-tab>
              <b-tab :title="$t('SIGNATURE')">
              <EditSignature :userinfo="information"></EditSignature>
              </b-tab>
            </b-tabs>
          </div>
        </b-tab>

        <b-tab lazy v-if="user.information.is_admin != 0 && user.information.user_uuid != information.user_uuid">
          <template v-slot:title>
          {{
            $t("EDIT")
          }}
            </template>  
            <div class="mt-2">
              <b-tabs content-class="mt-2">
                <b-tab :title="$t('INFORMATION')" active>
                  <Edit @updatedUser="updatedUser" @removedUser="removedUser" :userinfo="information"></Edit>
                </b-tab>
                <b-tab :title="$t('AUTH_METHODS.TITLE')" v-if="user.information.is_admin > 0">
                  <EditAuth @updatedUser="updatedUser" :userinfo="information"></EditAuth>    
                </b-tab>
                <b-tab :title="$t('OUT_OF_OFFICE')">
                  <EditOutOfOffice :userinfo="information"></EditOutOfOffice>
                </b-tab>
                <b-tab :title="$t('SIGNATURE')">
                  <EditSignature :userinfo="information"></EditSignature>
                </b-tab>
              </b-tabs>
            </div>
        </b-tab>

        <b-tab v-if="showDelegateAccess" lazy>
          <template v-slot:title>{{
              $t("DELEGATE_ACCESS")
            }}
          </template>  
          <DelegatedUsers :userinfo="information"></DelegatedUsers>
        </b-tab>



      </b-tabs>
    
    </div>
  </section>
</template>
<script>
import Settings from '@/components/User/Settings';
import DelegatedUsers from '@/components/User/DelegatedUsers';
import Edit from '@/components/User/Edit';
import Information from '@/components/User/Information';
import EditAuth from '@/components/User/EditAuth';
import EditSignature from '@/components/User/EditSignature';
import EditOutOfOffice from '@/components/User/EditOutOfOffice';
export default {
  props: ["user_uuid"],
  components: {
    Settings,
    Edit,
    Information,
    EditAuth,
    EditOutOfOffice,
    DelegatedUsers,
    EditSignature
  },
  data() {
    return {
      information: null
    };
  },
  methods: {
    updatedUser: function(information) {
      this.$emit("updatedUser",{
        auth_type: information.authentication_type,
        auth_method: information.authentication_method,
        locale: information.locale,
        name: information.name,
        state: information.state,
        is_admin: information.is_admin
      });
    },
    removedUser: function() {
      this.$emit("removedUser")
    },
    getInformation: function() {
      let self = this;
      this.$http
        .get(this.user.hostname + "/user/information/" + this.user_uuid)
        .then(response => {
          self.information = response.data;
        })
        .catch(() => {
        });
    }
  },
  watch: { 
    user_uuid: function(newVal, oldVal) { // watch it
      if(newVal != oldVal)
      {
        this.getInformation();
      }      
    }
  },
  computed: {
    showDelegateAccess(){
      return this.user.accounts_can_delegate;
    },
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    this.getInformation();
  }
};
</script>
<style></style>
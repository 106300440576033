var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        "hide-header": "",
        "hide-header-close": "",
        centered: "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "dialog-class": _vm.teams.theme,
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _vm.expired
                ? _c(
                    "div",
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "primary" },
                          on: { click: _vm.Logout },
                        },
                        [
                          _vm._v(
                            "\n        " + _vm._s(_vm.$t("LOGIN")) + "\n      "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.expired
                ? _c(
                    "div",
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "primary" },
                          on: { click: _vm.Refresh },
                        },
                        [
                          _vm._v(
                            "\n        " + _vm._s(_vm.$t("YES")) + "\n      "
                          ),
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "secondary" },
                          on: { click: _vm.Logout },
                        },
                        [
                          _vm._v(
                            "\n        " + _vm._s(_vm.$t("LOGOUT")) + "\n      "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _vm.expired
        ? _c("div", [_vm._v(_vm._s(_vm.$t("ACTIVITY_TIMEDOUT")))])
        : _vm._e(),
      !_vm.expired
        ? _c("div", [
            _c("p", [
              _vm._v(
                _vm._s(
                  _vm.$t("YOUR_SESSION_IS_ABOUT_TO_END_DO_YOU_WANT_TO_EXTEND")
                )
              ),
            ]),
            _c("p", [
              _vm._v(
                _vm._s(_vm.$t("EXPIRES")) +
                  " " +
                  _vm._s(_vm.FormatDateTime(_vm.expire)) +
                  " "
              ),
            ]),
            _vm._v(
              "\n\n    " +
                _vm._s(_vm.$t("YOU_HAVE")) +
                " " +
                _vm._s(_vm.minutes) +
                _vm._s(_vm.seconds) +
                " " +
                _vm._s(_vm.$t("LEFT_TO_EXTEND")) +
                "\n  "
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <b-modal hide-header hide-header-close v-model="show" centered no-close-on-backdrop no-close-on-esc
  :dialog-class="teams.theme">

    <div v-if="expired">{{ $t('ACTIVITY_TIMEDOUT') }}</div>
    <div v-if="!expired">
      <p >{{ $t('YOUR_SESSION_IS_ABOUT_TO_END_DO_YOU_WANT_TO_EXTEND') }}</p>

      <p>{{ $t('EXPIRES') }} {{ FormatDateTime(expire) }} </p>

      {{ $t('YOU_HAVE') }} {{ minutes }}{{ seconds }} {{  $t('LEFT_TO_EXTEND') }}
    </div>

    <template #modal-footer>
      <div v-if="expired">
        <b-button size="sm" variant="primary"   @click="Logout">
          {{ $t('LOGIN') }}
        </b-button>
      </div>
      <div v-if="!expired">
        <b-button size="sm" variant="primary" @click="Refresh">
          {{ $t('YES') }}
        </b-button>
        <b-button size="sm" variant="secondary"  @click="Logout">
          {{ $t('LOGOUT') }}
        </b-button>
      </div> 
    </template>
  </b-modal> 
</template>
<script>
export default {
  data() {
    return {
      show: false,
      expire: null,
      expired: false,
      current_time: null,
      updating: false,
      intervalId: ""
    };
  },
  methods: {
    async Refresh()
    {
      this.show = false;
      this.updating = true;
      await this.$store.dispatch("user/fetchUser");
      this.updating = false;
    },
    Logout(){
      this.$store.dispatch("user/NotAuthenticated");
      this.$cookies.remove("redirect_url");
      this.$cookies.remove("link_token");
      this.$router.push({ name: "Login" }).catch(() => {});
      this.$router.push({ name: "Login" }).catch(() => {});
    },
    async Init()
    {      
      this.intervalId = setInterval(this.UpdateClock , 1000, this)      
    },
    CheckTimeout()
    {
      if(!this.show && this.timeleft.minutes < this.check_minutes && !this.updating)
      {
        this.show = true;
      }
    },
    UpdateClock(){
      let token = this.$cookies.get("token");
      if(token != undefined && token != null && token != "")
      {
        let json = JSON.parse(Buffer.from((token.split('.')[1]), 'base64'));
        this.expire = this.$luxonDateTime.fromSeconds(json.exp);      
        this.check_minutes = parseInt(json.ticket_alarm);
        if(this.check_minutes == 0)
        {
          clearInterval(this.intervalId);
        }else{
          this.current_time = this.$luxonDateTime.utc();
          if(this.timeleft.minutes <= 0 && this.timeleft.seconds <= 0)
          {
            this.expired = true;
            clearInterval(this.intervalId);        
            this.$router.push({ name: "Login" }).catch(() => {});
          }
          this.CheckTimeout();
        }
      }
    }
  },
  computed: {
    timeleft()
    {
      if(this.current_time == null)
      {
        return null;
      }
      if(this.expire == null)
      {
        return null;
      }
      return this.expire.diff(this.current_time,["minutes", "seconds"]).toObject();
    },
    minutes(){
      if(this.timeleft == null){ return ""; }
      if(this.timeleft.minutes > 0)
      {
        if(this.timeleft.minutes == 1)
        {
          return Math.floor(this.timeleft.minutes) + " " + this.$t('MINUTE');
        }else{
          return Math.floor(this.timeleft.minutes) + " " + this.$t('MINUTES');
        }
      }
      return "";
    },
    seconds(){
      if(this.timeleft == null){ return ""; }
      let string = "";
      if(this.timeleft.minutes > 0)
      {
        string += " ";
      }
      if(this.timeleft.seconds > 0)
      {
        if(this.timeleft.minusecondstes == 1)
        {
          string += Math.floor(this.timeleft.seconds) + " " + this.$t('SECOND');
        }else{
          string += Math.floor(this.timeleft.seconds) + " " + this.$t('SECONDS');
        }
      }
      return string;
    }
  },
  mounted: function() {
    this.Init();
  },
};
</script>
<style></style>
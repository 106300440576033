var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user_uuid
    ? _c("section", [
        _vm.information
          ? _c(
              "div",
              { staticClass: "mt-2" },
              [
                _c(
                  "h3",
                  [
                    _c("b-img", {
                      staticClass: "profileImage",
                      attrs: {
                        src: "/img/default-avatar.png",
                        alt: _vm.information.name,
                        rounded: "circle",
                      },
                    }),
                    _vm._v(
                      "\n      " + _vm._s(_vm.information.name) + "\n    "
                    ),
                  ],
                  1
                ),
                _c(
                  "b-tabs",
                  [
                    _c(
                      "b-tab",
                      {
                        attrs: { lazy: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [
                                  _vm._v(
                                    _vm._s(_vm.$t("INFORMATION")) + "\n        "
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1071631124
                        ),
                      },
                      [
                        _c("Information", {
                          attrs: { userinfo: _vm.information },
                        }),
                      ],
                      1
                    ),
                    _vm.user.information.user_uuid == _vm.information.user_uuid
                      ? _c(
                          "b-tab",
                          {
                            attrs: { lazy: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        "\n         " +
                                          _vm._s(_vm.$t("SETTINGS")) +
                                          "\n        "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1786714692
                            ),
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "mt-2" },
                              [
                                _c(
                                  "b-tabs",
                                  { attrs: { "content-class": "mt-2" } },
                                  [
                                    _c(
                                      "b-tab",
                                      {
                                        attrs: {
                                          title: _vm.$t("INFORMATION"),
                                          active: "",
                                        },
                                      },
                                      [
                                        _c("Settings", {
                                          attrs: { userinfo: _vm.information },
                                          on: { updatedUser: _vm.updatedUser },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm.user.information.is_admin > 0
                                      ? _c(
                                          "b-tab",
                                          {
                                            attrs: {
                                              title:
                                                _vm.$t("AUTH_METHODS.TITLE"),
                                            },
                                          },
                                          [
                                            _c("EditAuth", {
                                              attrs: {
                                                userinfo: _vm.information,
                                              },
                                              on: {
                                                updatedUser: _vm.updatedUser,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "b-tab",
                                      {
                                        attrs: {
                                          title: _vm.$t("OUT_OF_OFFICE"),
                                        },
                                      },
                                      [
                                        _c("EditOutOfOffice", {
                                          attrs: { userinfo: _vm.information },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-tab",
                                      { attrs: { title: _vm.$t("SIGNATURE") } },
                                      [
                                        _c("EditSignature", {
                                          attrs: { userinfo: _vm.information },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.user.information.is_admin != 0 &&
                    _vm.user.information.user_uuid != _vm.information.user_uuid
                      ? _c(
                          "b-tab",
                          {
                            attrs: { lazy: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        "\n        " +
                                          _vm._s(_vm.$t("EDIT")) +
                                          "\n          "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              527163197
                            ),
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "mt-2" },
                              [
                                _c(
                                  "b-tabs",
                                  { attrs: { "content-class": "mt-2" } },
                                  [
                                    _c(
                                      "b-tab",
                                      {
                                        attrs: {
                                          title: _vm.$t("INFORMATION"),
                                          active: "",
                                        },
                                      },
                                      [
                                        _c("Edit", {
                                          attrs: { userinfo: _vm.information },
                                          on: {
                                            updatedUser: _vm.updatedUser,
                                            removedUser: _vm.removedUser,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm.user.information.is_admin > 0
                                      ? _c(
                                          "b-tab",
                                          {
                                            attrs: {
                                              title:
                                                _vm.$t("AUTH_METHODS.TITLE"),
                                            },
                                          },
                                          [
                                            _c("EditAuth", {
                                              attrs: {
                                                userinfo: _vm.information,
                                              },
                                              on: {
                                                updatedUser: _vm.updatedUser,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "b-tab",
                                      {
                                        attrs: {
                                          title: _vm.$t("OUT_OF_OFFICE"),
                                        },
                                      },
                                      [
                                        _c("EditOutOfOffice", {
                                          attrs: { userinfo: _vm.information },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-tab",
                                      { attrs: { title: _vm.$t("SIGNATURE") } },
                                      [
                                        _c("EditSignature", {
                                          attrs: { userinfo: _vm.information },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.showDelegateAccess
                      ? _c(
                          "b-tab",
                          {
                            attrs: { lazy: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        _vm._s(_vm.$t("DELEGATE_ACCESS")) +
                                          "\n        "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2088660380
                            ),
                          },
                          [
                            _c("DelegatedUsers", {
                              attrs: { userinfo: _vm.information },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user.information.out_of_office_enabled
    ? _c(
        "section",
        { staticClass: "out-of-office-message" },
        [
          _vm._v(
            "\n  " + _vm._s(_vm.$t("NARR.OUT_OF_OFFICE.ACCOUNT_ACTIVE")) + " "
          ),
          _c(
            "b-button",
            {
              staticClass: "no-border ml-2",
              attrs: { size: "sm" },
              on: { click: _vm.Deactivate },
            },
            [_vm._v(_vm._s(_vm.$t("DEACTIVATE")))]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <section>
    <form @submit.prevent  class="mt-0 pt-0">
      <b-row class="mt-0 pt-0">
        <b-col>
          <label class="col-form-label">{{ $t("PASSWORD") }}</label>
          <b-form-input trim
            type="password"
            @input="acceptData"
            autocomplete="off"
            ref="password1"
            v-model="password1"
          ></b-form-input>
          <b-form-invalid-feedback :state="errors.length == 0">
            <div
                      v-for="(item,index) in errors"
                      :key="index">
                      * {{item}}</div>
          </b-form-invalid-feedback>

        </b-col>
        <b-col>
          <label class="col-form-label">{{ $t("RETYPE_PASSWORD") }}</label>
          <b-form-input trim
            type="password"
            @input="acceptData"
            autocomplete="off"
            v-model="password2"
          ></b-form-input>

          <b-form-invalid-feedback :state="!password_not_matching">
            {{ $t("ERROR.PASSWORD_NOT_MATCHED") }}
          </b-form-invalid-feedback>
        </b-col>
      </b-row>
    </form>
  </section>
</template>
<script>
export default {
  data() {
    return {
      password1: "",
      password2: "",
      score: 0,
      errors: [],
			rules: [
				{ message: this.$t('ERROR.PASSWORD.ONE_LOWERCASE_REQUIRED'), regex:/[a-z]+/ },
				{ message: this.$t('ERROR.PASSWORD.ONE_UPPERCASE_REQUIRED'),  regex:/[A-Z]+/ },
				{ message: this.$t('ERROR.PASSWORD.CHARACTERS_REQUIRED'), regex:/.{8,}/ },
				{ message: this.$t('ERROR.PASSWORD.ONE_NUMBER_REQUIRED'), regex:/[0-9]+/ }
			]
    };
  },
  methods: {
    acceptData: function() {
      this.password1 = this.password1.replace(/\s/g, "");
      this.password2 = this.password2.replace(/\s/g, "");
      if (this.password1 == "") {
        this.$emit("valid", false);
        return false;
      }
      let errors = []
			for (let condition of this.rules) {
				if (!condition.regex.test(this.password1)) {
					errors.push(condition.message)
				}
			}
			if (errors.length === 0) {
        this.errors = [];
			} else {
        this.errors = errors;
        this.$emit("valid", false);
        return false;
			}
      if (this.password1 != this.password2) {
        this.$emit("valid", false);
        return false;
      }
      this.$emit("valid", true);
      let retVal = {
        password1: this.password1,
        password2: this.password2       
      };
      this.$emit("changed", retVal);
    }
  },
  computed: {
    password_not_matching() {
      return this.password1 != this.password2;
    }
  },
  mounted() {
    this.$refs["password1"].$el.focus();
  },
};
</script>
<style></style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.validations.length != 0
    ? _c(
        "b-modal",
        {
          attrs: {
            "hide-header": "",
            "hide-header-close": "",
            centered: "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "dialog-class": _vm.teams.theme,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "modal-footer",
                fn: function () {
                  return [
                    _c(
                      "b-button",
                      {
                        attrs: { size: "sm", variant: "secondary" },
                        on: { click: _vm.close },
                      },
                      [_vm._v("\n      " + _vm._s(_vm.$t("CLOSE")) + "\n    ")]
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            1799909285
          ),
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "b-list-group",
            { attrs: { flush: "" } },
            _vm._l(_vm.validations, function (validation, key) {
              return _c("b-list-group-item", { key: key }, [
                _vm._v(
                  "\n    " + _vm._s(validation.validationMessage) + "\n    "
                ),
                _vm.showData(validation)
                  ? _c("p", { staticClass: "mt-2" }, [
                      _vm._v("\n      " + _vm._s(validation.data) + " "),
                    ])
                  : _vm._e(),
              ])
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <section class="mt-3">      
    
        <h5 class="mt-2">{{ $t("NAME") }}</h5>
        <div>{{ information.name }}</div>

        <h5 class="mt-2">{{ $t("EMAIL") }}</h5>
        <div>{{ information.email }}</div>

        <h5 class="mt-2" v-if="information.title">{{ $t("TITLE") }}</h5>
        <div v-if="information.title">{{ information.title }}</div>

        <h5 class="mt-2" v-if="information.department">{{ $t("DEPARTMENT") }}</h5>
        <div v-if="information.department">{{ information.department }}</div>

        <h5 class="mt-2">{{ $t('LAST_ACTIVITY') }}</h5>
        <div>{{ FormatDateTime(information.last_activity) }}</div>

        <h5 class="mt-2">{{ $t('CREATED') }}</h5>
        <div>{{ FormatDateTime(information.created_date) }}</div>     

  </section>
</template>
<script>
export default {
  props: ["userinfo"],
  data() {
    return {
      information: this.userinfo
    };
  },
  methods: {    
    showInvite: function(state) {
      return (state === 0) ?  this.$t("NO") : this.$t("YES");
    },
    showValidated: function(state) {
      return (state === 0) ? this.$t("NO") : this.$t("YES");
    },
    showAdmin: function(state) {
      return (state === 0) ? this.$t("NO") : this.$t("YES");
    }
  },
  mounted: function() {
  }
};
</script>
<style>
</style>
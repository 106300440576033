var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "mt-3" },
    [
      _c(
        "b-overlay",
        { attrs: { show: _vm.loading, rounded: "sm" } },
        [
          _c("div", { staticClass: "mt-1" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$t("OUT_OF_THE_OFFICE.AUTOMATIC_REPLY_INFORMATION_TEXT")
                ) +
                "\n    "
            ),
          ]),
          _c("h5", { staticClass: "mt-3" }, [
            _vm._v(
              _vm._s(_vm.$t("OUT_OF_THE_OFFICE.ACTIVATE_AUTOMATIC_REPLIES"))
            ),
          ]),
          _c(
            "div",
            { staticClass: "mt-1" },
            [
              _c("b-form-checkbox", {
                attrs: {
                  switch: "",
                  "aria-label": _vm.$t(
                    "NARR.OUT_OF_OFFICE.SWITCH_TO_ACTIVATE_AUTO_REPLY"
                  ),
                },
                on: { change: _vm.handleOutOfOfficeChange },
                model: {
                  value: _vm.out_of_office_enabled,
                  callback: function ($$v) {
                    _vm.out_of_office_enabled = $$v
                  },
                  expression: "out_of_office_enabled",
                },
              }),
            ],
            1
          ),
          _c("h5", { staticClass: "mt-3" }, [
            _vm._v(
              _vm._s(
                _vm.$t("OUT_OF_THE_OFFICE.SEND_REPLIES_UNDER_SPECIFIC_TIME")
              )
            ),
          ]),
          _c(
            "div",
            { staticClass: "mt-1" },
            [
              _c("b-form-checkbox", {
                attrs: {
                  switch: "",
                  disabled: !_vm.out_of_office_enabled,
                  "aria-label": _vm.$t(
                    "NARR.OUT_OF_OFFICE.SWITCH_TO_SPECIFY_DATE_TIME_FOR_AUTO_REPLY"
                  ),
                },
                model: {
                  value: _vm.use_start_end_date,
                  callback: function ($$v) {
                    _vm.use_start_end_date = $$v
                  },
                  expression: "use_start_end_date",
                },
              }),
            ],
            1
          ),
          _c("PickDate", {
            attrs: {
              "use-label": _vm.$t("FROM"),
              "use-clock": "",
              isDisabled: !_vm.use_start_end_date,
            },
            on: { valid: (n) => (_vm.validStart = n) },
            model: {
              value: _vm.startDateTime,
              callback: function ($$v) {
                _vm.startDateTime = $$v
              },
              expression: "startDateTime",
            },
          }),
          _c("PickDate", {
            attrs: {
              "start-date": _vm.startDateTime,
              "use-label": _vm.$t("TO"),
              isDisabled: !_vm.use_start_end_date,
              "use-clock": "",
            },
            on: { valid: (n) => (_vm.validEnd = n) },
            model: {
              value: _vm.endDateTime,
              callback: function ($$v) {
                _vm.endDateTime = $$v
              },
              expression: "endDateTime",
            },
          }),
          _vm.out_of_office_enabled
            ? _c("h5", { staticClass: "mt-3" }, [
                _vm._v(_vm._s(_vm.$t("MESSAGE"))),
              ])
            : _vm._e(),
          _vm.out_of_office_enabled
            ? _c(
                "div",
                { staticClass: "mt-1" },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      rows: "5",
                      "max-rows": "6",
                      "aria-label": _vm.$t(
                        "NARR.OUT_OF_OFFICE.INPUT_TO_ENTER_AUTO_REPLY_MESSAGE"
                      ),
                    },
                    model: {
                      value: _vm.out_of_office_message,
                      callback: function ($$v) {
                        _vm.out_of_office_message = $$v
                      },
                      expression: "out_of_office_message",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("hr", { staticClass: "p-0 m-0 mt-4 mb-2" }),
          _c(
            "b-button",
            {
              staticClass: "btn-fill",
              attrs: { variant: "primary", disabled: !_vm.isFormValid },
              on: { click: _vm.saveSubmit },
            },
            [_vm._v(_vm._s(_vm.$t("SAVE")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
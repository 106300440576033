<template>
  <section>
    <b-dropdown
      id="dropdown-dropup"
      dropup
      size="sm"
      toggle-class="text-decoration-none"
      class="m-2 no-border"
    >
      <template v-slot:button-content>
        <b-img class="flag" :src="currentFlag" :alt="getSelectedValue"></b-img> {{ getSelectedValue }}
      </template>
      <b-dropdown-item @click="setLocale(key)"
        v-for="(value, key) in sefos_locales"
        :key="key"
      >
        <b-img class="flag" :src="getFlag(key)" :alt="$t('LANGUAGE.' + key)"></b-img>
        {{ $t("LANGUAGE." + key) }}
      </b-dropdown-item>
    </b-dropdown>

  </section>
</template>
<script>
export default {
  data() {
    return {
      showLanguages: false
    };
  },
  methods: {
    setLocale(locale){      
      this.$i18n.locale = locale;
      this.$store.dispatch("lang/setLocale", locale);
    },
    getFlag(locale) {
      return "/img/flags/" + locale + ".png";
    },
    showlanguage() {
      this.showLanguages = true;
    },
  },
  computed: {
    getSelected() {
      return this.$i18n.locale;
    },
    getSelectedValue() {
      return this.$t("LANGUAGE." + this.$i18n.locale);
    },
    currentFlag() {
      return "/img/flags/" + this.$i18n.locale + ".png";
    },
  },
};
</script>

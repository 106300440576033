<template>
  <b-form-input trim
    @input="acceptData"
    autocomplete="off"
    :placeholder="placeholder"
    v-model="input_value"
  ></b-form-input>
</template>
<script>
export default {
  props: ["placeholder", "value"],
  data() {
    return {
      input_value: this.value,
    };
  },
  methods: {
    acceptData: function () {
      this.input_value = this.input_value.replace(/\s/g, "");
      if (this.input_value == "") {
        this.$emit("valid", false);
      } else {
        this.$emit("valid", true);
      }
      this.$emit("input", this.input_value);
    },
    formatter(value) {
      return value.toLowerCase();
    },
  },
  mounted() {
    if (this.value == "") {
      this.$emit("valid", false);
    }
  },
};
</script>
<style></style>

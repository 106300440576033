var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user.information
    ? _c(
        "div",
        {
          staticClass: "d-flex flex-column h-100",
          attrs: {
            id: "sidebar-wrapper",
            backdrop: "",
            "no-slide": "",
            shadow: "",
          },
        },
        [_c("Menu", { on: { clickedMenu: _vm.clickedMenu } })],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
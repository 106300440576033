var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.userinfo
    ? _c(
        "section",
        { staticClass: "mt-2" },
        [
          _c(
            "b-tabs",
            [
              _c(
                "b-tab",
                {
                  attrs: { title: _vm.$t("ACCESS"), active: "" },
                  on: { click: _vm.cancelAdd },
                },
                [
                  _c(
                    "b-form",
                    { staticClass: "mt-2", attrs: { inline: "" } },
                    [
                      _c("b-form-input", {
                        staticClass: "searchText newline-mobile ml-0",
                        attrs: { trim: "", placeholder: _vm.$t("SEARCH") },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            $event.preventDefault()
                          },
                        },
                        model: {
                          value: _vm.searchText,
                          callback: function ($$v) {
                            _vm.searchText = $$v
                          },
                          expression: "searchText",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("b-table", {
                    staticClass: "mt-2",
                    attrs: {
                      small: "",
                      busy: _vm.loading,
                      bordered: "",
                      items: _vm.currentItems,
                      hover: "",
                      fields: _vm.fields,
                      "tbody-tr-class": _vm.rowClass,
                    },
                    on: { "row-clicked": _vm.clickedRow },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "head(name)",
                          fn: function () {
                            return [
                              _vm.currentItems.length != 0
                                ? _c("b-form-checkbox", {
                                    on: { change: _vm.removeAll },
                                    model: {
                                      value: _vm.remove_all,
                                      callback: function ($$v) {
                                        _vm.remove_all = $$v
                                      },
                                      expression: "remove_all",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("NAME")) +
                                  "\n        "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "head(id)",
                          fn: function () {
                            return [
                              _vm.currentItems.length != 0
                                ? _c("b-form-checkbox", {
                                    on: { change: _vm.removeAll },
                                    model: {
                                      value: _vm.remove_all,
                                      callback: function ($$v) {
                                        _vm.remove_all = $$v
                                      },
                                      expression: "remove_all",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v("\n           \n        "),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "cell(expire_date)",
                          fn: function (data) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.FormatDateTime(data.item.expire_date)
                                  ) +
                                  "\n        "
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(name)",
                          fn: function (data) {
                            return [
                              _c(
                                "b-form",
                                { attrs: { inline: "" } },
                                [
                                  _c("b-form-checkbox", {
                                    attrs: { value: data.item.user_uuid },
                                    model: {
                                      value:
                                        _vm.RemoveDelegatedAccountDTO
                                          .user_uuids,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.RemoveDelegatedAccountDTO,
                                          "user_uuids",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "RemoveDelegatedAccountDTO.user_uuids",
                                    },
                                  }),
                                  data.item.new_messages == 0
                                    ? _c("span", [
                                        _c("i", {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover",
                                              modifiers: { hover: true },
                                            },
                                          ],
                                          staticClass: "fal fa-history",
                                          attrs: {
                                            title: _vm.$t(
                                              "CAN_VIEW_ALL_MESSAGES"
                                            ),
                                          },
                                        }),
                                        _vm._v("  "),
                                      ])
                                    : _vm._e(),
                                  _c("b-img", {
                                    staticClass: "profileImage",
                                    attrs: {
                                      src: "/img/default-avatar.png",
                                      alt: data.item.name,
                                      rounded: "circle",
                                    },
                                  }),
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(data.item.name) +
                                      "\n          "
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(email)",
                          fn: function (data) {
                            return [_vm._v(_vm._s(data.item.email))]
                          },
                        },
                        {
                          key: "cell(is_admin)",
                          fn: function (data) {
                            return [
                              _vm._v(_vm._s(_vm.showAdmin(data.item.is_admin))),
                            ]
                          },
                        },
                        {
                          key: "cell(id)",
                          fn: function (data) {
                            return [
                              _c(
                                "b-form",
                                { attrs: { inline: "" } },
                                [
                                  _c("b-form-checkbox", {
                                    attrs: { value: data.item.user_uuid },
                                    model: {
                                      value:
                                        _vm.RemoveDelegatedAccountDTO
                                          .user_uuids,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.RemoveDelegatedAccountDTO,
                                          "user_uuids",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "RemoveDelegatedAccountDTO.user_uuids",
                                    },
                                  }),
                                  data.item.new_messages == 0
                                    ? _c("span", [
                                        _c("i", {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover",
                                              modifiers: { hover: true },
                                            },
                                          ],
                                          staticClass: "fal fa-history",
                                          attrs: {
                                            title: _vm.$t(
                                              "CAN_VIEW_ALL_MESSAGES"
                                            ),
                                          },
                                        }),
                                        _vm._v("  "),
                                      ])
                                    : _vm._e(),
                                  _c("UserInfo", {
                                    attrs: {
                                      image: "/img/default-avatar.png",
                                      primary: data.item.name,
                                      secondary: data.item.email,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "table-busy",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticClass: "text-center my-2" },
                                [
                                  _c("b-spinner", {
                                    staticClass: "align-middle",
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3825025068
                    ),
                  }),
                  _c("b-pagination", {
                    staticClass: "mt-2",
                    attrs: {
                      "total-rows": _vm.totalCurrentItems,
                      "per-page": _vm.limit,
                      align: "center",
                      pills: "",
                    },
                    on: { input: _vm.getCurrentUsers },
                    model: {
                      value: _vm.currentPage,
                      callback: function ($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage",
                    },
                  }),
                  _c("hr", { staticClass: "p-0 m-0 mt-2 mb-2" }),
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-fill",
                      attrs: { disabled: !_vm.validRemove, variant: "danger" },
                      on: { click: _vm.removeBtn },
                    },
                    [_vm._v(_vm._s(_vm.$t("REMOVE")))]
                  ),
                ],
                1
              ),
              _c(
                "b-tab",
                {
                  attrs: { title: _vm.$t("ADD") },
                  on: { click: _vm.showAddBtn },
                },
                [
                  _c(
                    "b-form",
                    { staticClass: "mt-2", attrs: { inline: "" } },
                    [
                      _c("b-form-input", {
                        staticClass: "searchText newline-mobile ml-0",
                        attrs: { trim: "", placeholder: _vm.$t("SEARCH") },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            $event.preventDefault()
                          },
                        },
                        model: {
                          value: _vm.searchText,
                          callback: function ($$v) {
                            _vm.searchText = $$v
                          },
                          expression: "searchText",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("b-table", {
                    staticClass: "mt-2",
                    attrs: {
                      small: "",
                      busy: _vm.loading,
                      bordered: "",
                      items: _vm.items,
                      fields: _vm.fieldsFree,
                    },
                    on: { "row-clicked": _vm.clickedRow },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "head(name)",
                          fn: function () {
                            return [
                              _vm.items.length != 0
                                ? _c("b-form-checkbox", {
                                    on: { change: _vm.addAll },
                                    model: {
                                      value: _vm.add_all,
                                      callback: function ($$v) {
                                        _vm.add_all = $$v
                                      },
                                      expression: "add_all",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("NAME")) +
                                  "\n        "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "head(id)",
                          fn: function () {
                            return [
                              _vm.items.length != 0
                                ? _c("b-form-checkbox", {
                                    on: { change: _vm.addAll },
                                    model: {
                                      value: _vm.add_all,
                                      callback: function ($$v) {
                                        _vm.add_all = $$v
                                      },
                                      expression: "add_all",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v("\n           \n        "),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "cell(name)",
                          fn: function (data) {
                            return [
                              _c(
                                "b-form",
                                { attrs: { inline: "" } },
                                [
                                  _c("b-form-checkbox", {
                                    attrs: { value: data.item.user_uuid },
                                    model: {
                                      value:
                                        _vm.AddDelegatedAccountDTO.user_uuids,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.AddDelegatedAccountDTO,
                                          "user_uuids",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "AddDelegatedAccountDTO.user_uuids",
                                    },
                                  }),
                                  _c("b-img", {
                                    staticClass: "profileImage",
                                    attrs: {
                                      src: "/img/default-avatar.png",
                                      alt: data.item.name,
                                      rounded: "circle",
                                    },
                                  }),
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(data.item.name) +
                                      "\n          "
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(email)",
                          fn: function (data) {
                            return [_vm._v(_vm._s(data.item.email))]
                          },
                        },
                        {
                          key: "cell(is_admin)",
                          fn: function (data) {
                            return [
                              _vm._v(_vm._s(_vm.showAdmin(data.item.is_admin))),
                            ]
                          },
                        },
                        {
                          key: "cell(id)",
                          fn: function (data) {
                            return [
                              _c(
                                "b-form",
                                { attrs: { inline: "" } },
                                [
                                  _c("b-form-checkbox", {
                                    attrs: { value: data.item.user_uuid },
                                    model: {
                                      value:
                                        _vm.AddDelegatedAccountDTO.user_uuids,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.AddDelegatedAccountDTO,
                                          "user_uuids",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "AddDelegatedAccountDTO.user_uuids",
                                    },
                                  }),
                                  _c("UserInfo", {
                                    attrs: {
                                      image: "/img/default-avatar.png",
                                      primary: data.item.name,
                                      secondary: data.item.email,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "table-busy",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticClass: "text-center my-2" },
                                [
                                  _c("b-spinner", {
                                    staticClass: "align-middle",
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      1063033928
                    ),
                  }),
                  _c("b-pagination", {
                    staticClass: "mt-2",
                    attrs: {
                      "total-rows": _vm.totalItems,
                      "per-page": _vm.limit,
                      align: "center",
                      pills: "",
                    },
                    on: { input: _vm.getUsers },
                    model: {
                      value: _vm.usersPage,
                      callback: function ($$v) {
                        _vm.usersPage = $$v
                      },
                      expression: "usersPage",
                    },
                  }),
                  _c("hr", { staticClass: "p-0 m-0 mt-2 mb-2" }),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.add-delegated-settings",
                          modifiers: { "add-delegated-settings": true },
                        },
                      ],
                      staticClass: "btn-fill",
                      attrs: { disabled: !_vm.valid, variant: "primary" },
                    },
                    [_vm._v(_vm._s(_vm.$t("ADD")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-modal",
            {
              attrs: {
                id: "add-delegated-settings",
                "hide-header": "",
                "dialog-class": _vm.teams.theme,
                centered: "",
                "ok-disabled":
                  _vm.AddDelegatedAccountDTO.expire_date == null &&
                  !_vm.validExpireTime,
                "cancel-title": _vm.$t("CANCEL"),
                "ok-title": _vm.$t("ADD"),
              },
              on: { cancel: _vm.cancelSave, ok: _vm.okSave },
            },
            [
              _vm._v("\n    " + _vm._s(_vm.$t("EXPIRES")) + "\n\n    "),
              _c("PickDate", {
                attrs: { "use-clock": "" },
                on: { valid: (n) => (_vm.validExpireTime = n) },
                model: {
                  value: _vm.AddDelegatedAccountDTO.expire_date,
                  callback: function ($$v) {
                    _vm.$set(_vm.AddDelegatedAccountDTO, "expire_date", $$v)
                  },
                  expression: "AddDelegatedAccountDTO.expire_date",
                },
              }),
              _c(
                "b-form-checkbox",
                {
                  staticClass: "mt-2",
                  attrs: {
                    id: "checkbox-1",
                    name: "checkbox-1",
                    value: 1,
                    switch: "",
                    "unchecked-value": 0,
                  },
                  model: {
                    value: _vm.AddDelegatedAccountDTO.new_messages,
                    callback: function ($$v) {
                      _vm.$set(_vm.AddDelegatedAccountDTO, "new_messages", $$v)
                    },
                    expression: "AddDelegatedAccountDTO.new_messages",
                  },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("SHOW_ONLY_NEW_MESSAGES")) +
                      "\n    "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "mt-3" }, [
    _c("h5", { staticClass: "mt-2" }, [_vm._v(_vm._s(_vm.$t("NAME")))]),
    _c("div", [_vm._v(_vm._s(_vm.information.name))]),
    _c("h5", { staticClass: "mt-2" }, [_vm._v(_vm._s(_vm.$t("EMAIL")))]),
    _c("div", [_vm._v(_vm._s(_vm.information.email))]),
    _vm.information.title
      ? _c("h5", { staticClass: "mt-2" }, [_vm._v(_vm._s(_vm.$t("TITLE")))])
      : _vm._e(),
    _vm.information.title
      ? _c("div", [_vm._v(_vm._s(_vm.information.title))])
      : _vm._e(),
    _vm.information.department
      ? _c("h5", { staticClass: "mt-2" }, [
          _vm._v(_vm._s(_vm.$t("DEPARTMENT"))),
        ])
      : _vm._e(),
    _vm.information.department
      ? _c("div", [_vm._v(_vm._s(_vm.information.department))])
      : _vm._e(),
    _c("h5", { staticClass: "mt-2" }, [
      _vm._v(_vm._s(_vm.$t("LAST_ACTIVITY"))),
    ]),
    _c("div", [
      _vm._v(_vm._s(_vm.FormatDateTime(_vm.information.last_activity))),
    ]),
    _c("h5", { staticClass: "mt-2" }, [_vm._v(_vm._s(_vm.$t("CREATED")))]),
    _c("div", [
      _vm._v(_vm._s(_vm.FormatDateTime(_vm.information.created_date))),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <b-modal hide-header hide-header-close v-model="show" centered no-close-on-backdrop no-close-on-esc
  :dialog-class="teams.theme" v-if="validations.length != 0">

  <b-list-group flush>
    <b-list-group-item v-for="(validation, key) in validations" :key="key">
      {{validation.validationMessage}}
      <p class="mt-2" v-if="showData(validation)">
        {{  validation.data }} </p>
    </b-list-group-item>
  </b-list-group>

    <template #modal-footer>
      <b-button size="sm" variant="secondary"  @click="close">
        {{ $t('CLOSE') }}
      </b-button>
    </template>
  </b-modal> 
</template>
<script>
export default {
  data() {
    return {
      show: true
    };
  },
  methods: {
    showData(item)
    {
      if(item.validation == "SEFOS_OFFLINE") { return true; }
      if(item.validation == "EXCEPTION") { return true; }
      return false;
    },
    async close()
    {
      let logout = this.validations.filter(function (item) { return item.validation == 'MISSING_TOKENINFORMATION' }).length != 0;
      if(logout)
      {
        await this.$store.dispatch('user/NotAuthenticated');
        this.$router.push({ name: "Login" }).catch(() => {});
      }
      await this.$store.dispatch("errors/removeValidations");
      this.show = false;
    },
    getVariant(validation)
    {
      switch(validation.status)
      {
        case "Error": return 'danger';
        case "Warning": return "warning";
        default: return 'info';
      }
    }
  },
  computed: {
    validations: function () {
      let validations = this.$store.state.errors.validations;
      if(this.$route.name == 'Login')
      {
        let disable = validations.filter(function (item) { return item.validation == 'MISSING_TOKENINFORMATION' }).length != 0;
        if(disable) { return []; }
      }
      const ids = [...new Set(validations.map(x => x.validation))];
      const distinct = ids.map(validation => validations.find(x => x.validation === validation));
      return distinct;
    },
    errorValidation(){
      return this.validations.filter(function (item) { return item.status == 'Error' || item.status == 'Warning' });
    }
  },
  watch: {
    validations: function () {
      this.show = this.errorValidation.length != 0;
    }
  },
  mounted: function() {
  },
};
</script>
<style></style>
<template>
  <section class="out-of-office-message" v-if="user.information.out_of_office_enabled">
    {{ $t('NARR.OUT_OF_OFFICE.ACCOUNT_ACTIVE')}} <b-button size="sm" class="no-border ml-2" @click="Deactivate">{{ $t('DEACTIVATE') }}</b-button>
  </section>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  methods: {
    async Deactivate(){
      try {
        let dto = {
            account_id: this.user.information.id,
            out_of_office_enabled: false,
            out_of_office_message: this.user.information.out_of_office_message,
            out_of_office_start_date: "0001-01-01T00:00:00Z",
            out_of_office_end_date: "9999-12-31T23:59:59Z"
        };
        await this.$http.post(`${this.user.hostname}/user/edit/out-of-office`,dto);
        this.$noty.info(this.$t('DEACTIVATED'));
        await this.$store.dispatch("user/fetchUser");
      // eslint-disable-next-line no-empty
      } catch {}
    }
  },
  computed: {
  },
  mounted: function() {
  },
};
</script>
<style></style>